import { Box, styled } from '@mui/material';

export const StyledSwitchContainer = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 0',
    margin: '3px',
  };
});
