import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import useDynamicScript from '../../hooks/useDynamicScript';
import AppConfig from '../../appConfig';
import { useGlobalNavData } from '../../hooks/queries/useGlobalNavData';
import { useSession } from '../../hooks/queries/useSession';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'hb-global-navigator': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

interface DataProps {
  appData?: { [key: string]: any };
  sessionData?: { [key: string]: any };
}

type GlobalNavigatorProps = DataProps;
type CustomHTMLElement = HTMLElement & DataProps;

const GlobalNavigator: React.FC<GlobalNavigatorProps> = () => {
  const navigatorRef = useRef<CustomHTMLElement | null>(null) as React.MutableRefObject<CustomHTMLElement | null>;
  const [isNavigatorMounted, setIsNavigatorMounted] = useState(false);

  const { isModuleReady: isGlobalNavScriptLoaded } = useDynamicScript(
    `${AppConfig.webComponentsUrl}/global-navigator/index.js`,
  );

  const { data: session } = useSession();
  if (!session) return null;
  const { data: appData } = useGlobalNavData(session.org_id, session.uid, true);

  useEffect(() => {
    if (!isNavigatorMounted) return;

    if (appData && session) {
      navigatorRef.current!.appData = appData;
      navigatorRef.current!.sessionData = session;
    }
  }, [appData, session, isNavigatorMounted]);

  return (
    isGlobalNavScriptLoaded && (
      <Box className="global-nav-wrapper">
        <hb-global-navigator
          ref={(element) => {
            navigatorRef.current = element;
            setIsNavigatorMounted(!!element);
          }}
          app-id="launchpad"
          app-origin={window.location.origin}
          display-mode="panel"
          exclude-session-and-global-nav-api-calls="true"
        />
      </Box>
    )
  );
};

export default GlobalNavigator;
