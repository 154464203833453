import { Box, useTheme, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const StyledSectionTitle = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.emphasis.value,
  };
});

export const FavoritesAppContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    padding: tokens.core.spacing['2'].value,
    backgroundColor: tokens.semantic.color.background.container.value,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flexStart',
    gap: tokens.core.spacing['1_5'].value,
    borderRadius: tokens.core.spacing['1_5'].value,
    marginBottom: semantic.iconSize['2xl'].value, //48px
  };
});

export const EmptyCardsSection = styled(Box)(() => {
  const { tokens } = useTheme();

  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.core.spacing['2'].value,
    paddingBottom: tokens.core.spacing['1_5'].value,
    alignSelf: 'stretch',
    overflow: 'hidden',
  };
});

export const FavoriteSectionHeader = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
  };
});

export const FavoriteSectionHeaderCols = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: semantic.spacing['2xl'].value,
  };
});

export const SkeletonSectionSubTitle = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.regular.value,
    fontSize: '14px',
    lineHeight: '20px !important',
  };
});
