import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { FavoriteCardComponentType, PaginationProps } from '../../../types';
import { Box, Button, useTheme } from '@mui/material';
import FavoriteCard from '../FavoriteCard';
import '@diligentcorp/atlas-web-component-icons/lib/dot/index.js';
import '@diligentcorp/atlas-web-component-icons/lib/expand-left/index.js';
import '@diligentcorp/atlas-web-component-icons/lib/expand-right/index.js';
import {
  PaginationButtonContainer,
  PaginationDotsIconContainer,
  CarouselContainer,
  DotIcon,
} from './PaginationSection.styles';
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dil-expand-left-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'dil-expand-right-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'dil-dot-icon': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const PaginationSection = ({ FavoriteAppsData }: PaginationProps): ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1); // Dynamically calculated
  const carouselContainerRef = useRef<HTMLDivElement>(null); // Ref for the carousel container
  const totalPages = Math.ceil(FavoriteAppsData.length / itemsPerPage);
  const { tokens } = useTheme();

  // Function to handle smooth scrolling
  const scrollCarousel = (page: number) => {
    const carousel = carouselContainerRef.current;
    if (carousel) {
      const scrollAmount = carousel.offsetWidth * (page - 1); // Scroll to the correct page
      carousel.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      scrollCarousel(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      scrollCarousel(prevPage);
    }
  };

  // Dynamically calculate the number of items that fit in the viewport
  const updateItemsPerPage = () => {
    const containerWidth = carouselContainerRef.current?.offsetWidth || 0;
    const cardWidth = carouselContainerRef.current?.children[0]?.clientWidth || 300; // Each card width (estimate)
    const calculatedItemsPerPage = Math.floor(containerWidth / cardWidth);
    setItemsPerPage(calculatedItemsPerPage || 1); // At least 1 item
  };

  useEffect(() => {
    updateItemsPerPage(); // Calculate on load
    window.addEventListener('resize', updateItemsPerPage); // Recalculate on resize
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);

  useEffect(() => {
    scrollCarousel(currentPage); // Scroll to the current page on load
  }, [currentPage]);

  function handleFavoriteClick(): void {
    return;
  }

  return (
    <Box>
      {/* Carousel container */}
      <CarouselContainer ref={carouselContainerRef}>
        {FavoriteAppsData.map((favoritedApp, index) => (
          <FavoriteCard
            key={favoritedApp.appKey}
            favoriteAppData={favoritedApp}
            onFavoriteButtonClick={handleFavoriteClick}
            componentType={FavoriteCardComponentType.Default}
          />
        ))}
      </CarouselContainer>

      {/* Pagination controls */}
      <PaginationButtonContainer>
        <PaginationDotsIconContainer>
          {Array.from({ length: totalPages }, (_, pageNumber) => (
            <DotIcon
              key={pageNumber}
              onClick={() => {
                setCurrentPage(pageNumber + 1);
                scrollCarousel(pageNumber + 1);
              }}
              isActive={currentPage === pageNumber + 1}
              size="small"
            >
              <dil-dot-icon />
            </DotIcon>
          ))}
        </PaginationDotsIconContainer>
        <Box>
          <Button
            sx={{ width: '32px', marginRight: tokens.core.spacing['1_5'].value }}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            size="small"
          >
            <dil-expand-left-icon />
          </Button>
          <Button sx={{ width: '32px' }} onClick={handleNextPage} disabled={currentPage === totalPages} size="small">
            <dil-expand-right-icon />
          </Button>
        </Box>
      </PaginationButtonContainer>
    </Box>
  );
};

export default PaginationSection;
