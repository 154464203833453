import React, { useState, FC, ReactElement } from 'react';
import type { LensTheme } from '@diligentcorp/atlas-theme-mui/lib/themes/lens/types';
import { Divider, Button, Drawer, useTheme } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import SwitchComponent from '../SwitchComponent/SwitchComponent';
import FavoriteSortableApps from '../FavoriteSortableApps';
import { useTranslation } from 'react-i18next';
import AppConfig from '../../appConfig';

interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  appKey?: string;
}

const FavoriteSection: FC<PopupDialogProps> = ({ open, onClose, title }): ReactElement => {
  const theme = useTheme<LensTheme>();
  const SideSheetPresets = theme.presets.SideSheetPersets;
  const { size, components } = SideSheetPresets;
  const { Header, Footer, Content } = components;

  const renderSideSheetContent = (
    <>
      <Header
        onClose={onClose}
        titleText={title}
        closeButtonProps={{ 'aria-label': 'Close side sheet', 'aria-controls': 'sideSheetId' }}
      />
      <Content>
        {renderFavoriteTabs()}
        {/* future implementaion not in scope */}
        {AppConfig.featureToggles.showFavoriteSwitch && (
          <>
            <SwitchComponent></SwitchComponent> <Divider></Divider>
          </>
        )}

        <FavoriteSortableApps></FavoriteSortableApps>
        {/* {renderFavoriteContent(appKey)} */}
      </Content>
      <Footer>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </Footer>
    </>
  );
  return (
    <Drawer open={open} onClose={onClose} sx={{ ...size['medium'].sx }}>
      {renderSideSheetContent}
    </Drawer>
  );
};

export default FavoriteSection;

function renderFavoriteTabs() {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(0);
  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: any) => {
    setSelectedValue(newValue);
  };
  return (
    <Tabs onChange={handleChange} value={selectedValue} aria-label="favorite-tabs">
      <Tab label={t('favoriteApps.tab_item_favorite')} key="tab-favorite" />
      <Tab label={t('favoriteApps.tab_item_section_rder')} key="tab-SectionOrder" />
    </Tabs>
  );
}
