import { Box, Typography, useTheme, CardContentProps } from '@mui/material';
import styled from '@emotion/styled';

export const SpinnerWrapper = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    alignItems: 'center',
    border: `1px solid ${semantic.color.action.disabled.value}`,
    borderRadius: semantic.radius.md.value,
    display: 'flex',
    height: '600px',
    justifyContent: 'center',
    width: '100%',
  };
});

export const StyledSectionTitle = styled(Typography)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    fontWeight: semantic.fontWeight.emphasis.value,
    paddingBottom: semantic.spacing['2xl'].value,
  };
});

export const StyledTilesContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: 'none',
    gap: semantic.spacing.lg.value,
    paddingBottom: semantic.iconSize['2xl'].value, //48px
  };
});

export const StyledCardContent = styled(Box)<CardContentProps<'a', { component: 'a' }>>`
  align-items: center;
  color: inherit;
  gap: 0;
  text-decoration: none;
  justify-content: space-evenly;
  &:focus-visible {
    outline: none;
  }
`;

export const StyledTabsContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return { paddingBottom: semantic.iconSize['2xl'].value }; //48px};
});

export const StyledAppMenuSection = styled(Box)(() => {
  return {
    float: 'right',
  };
});
