import React, { ReactElement } from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { StyledSwitchContainer } from './SwitchComponent.style';
import { useTranslation } from 'react-i18next';

const SwitchComponent = (): ReactElement => {
  const [state, setCheckedState] = React.useState({
    homepage: false,
    sidebar: false,
  });
  const { t } = useTranslation();

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Box style={{ width: '100%' }}>
      <FormControlLabel
        control={
          <StyledSwitchContainer>
            <span>{t('favoriteApps.switch_label_home_page')}</span>
            <Switch
              checked={state.homepage}
              onChange={handleCheckChange}
              aria-label="Show favorites on the Home Page?"
              role="switch"
              name="homepage"
            />
          </StyledSwitchContainer>
        }
        label=""
        labelPlacement="start"
      />
      <FormControlLabel
        control={
          <StyledSwitchContainer>
            <span>{t('favoriteApps.switch_label_side_bar')}</span>
            <Switch
              checked={state.sidebar}
              onChange={handleCheckChange}
              aria-label="Show favorites on the Home Page?"
              role="switch"
              name="sidebar"
            />
          </StyledSwitchContainer>
        }
        label=""
        labelPlacement="start"
      />
    </Box>
  );
};

export default SwitchComponent;
