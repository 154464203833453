import React, { ReactElement } from 'react';
import FavoriteCard from '../FavoriteCard/FavoriteCard';
import { FavoriteAppData, FavoriteCardComponentType } from '../../../types';
import {
  StyledSectionTitle,
  FavoritesAppContainer,
  EmptyCardsSection,
  SkeletonSectionSubTitle,
  FavoriteSectionHeader,
  FavoriteSectionHeaderCols,
} from './FavoriteAppSection.styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaginationSection from '../PaginationSection';

const FavoriteAppSection = (): ReactElement => {
  const { t } = useTranslation();
  const favoriteAppsList: FavoriteAppData[] = [
    {
      appKey: 'activity-center',
      appName: 'Activity Center',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'analytics',
      appName: 'Analytics',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'assessments',
      appName: 'Assessments',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'asset-inventory',
      appName: 'Asset Inventory',
      sectionName: 'issues-reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'assurance-plans',
      appName: 'Assurance Plans',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'boardeffect',
      appName: 'BoardEffect',
      sectionName: 'Boards & Governance',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'community',
      appName: 'Community',
      sectionName: 'Boards & Governance',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'frameworks',
      appName: 'Frameworks',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'issue-tracker',
      appName: 'Issue Tracker',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'market-intelligence',
      appName: 'DMI',
      sectionName: 'Market Intelligence',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'mission-control',
      appName: 'Mission Control',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'project-scheduler',
      appName: 'Scheduler',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'project-timesheets',
      appName: 'Timesheets',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'projects',
      appName: 'Projects',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'reports',
      appName: 'Reports',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'results',
      appName: 'Results',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'robots',
      appName: 'Robots',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'storyboards',
      appName: 'Storyboards',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'strategy',
      appName: 'Strategy',
      sectionName: 'Issues & Reporting',
      appLink: 'https://www.diligent.com/',
    },
    {
      appKey: 'task-tracker',
      appName: 'Task Tracker',
      sectionName: 'Audit & Analytics',
      appLink: 'https://www.diligent.com/',
    },
  ];

  const EmptyComponent = () => {
    function handleFavoriteClick(): void {
      return;
    }

    return (
      <Box>
        <EmptyCardsSection>
          {Array.from({ length: 3 }).map((_, index) => (
            <FavoriteCard
              key={index}
              favoriteAppData={favoriteAppsList[0]}
              onFavoriteButtonClick={handleFavoriteClick}
              componentType={FavoriteCardComponentType.Skeleton}
            />
          ))}
        </EmptyCardsSection>
        <SkeletonSectionSubTitle>{t('favoriteApps.section_sub_title')}</SkeletonSectionSubTitle>
      </Box>
    );
  };

  return (
    <>
      <FavoriteSectionHeader>
        <FavoriteSectionHeaderCols>
          <StyledSectionTitle variant="h2">{t('favoriteApps.section_title')}</StyledSectionTitle>
        </FavoriteSectionHeaderCols>
        <FavoriteSectionHeaderCols></FavoriteSectionHeaderCols>
      </FavoriteSectionHeader>
      <FavoritesAppContainer>
        {favoriteAppsList && favoriteAppsList.length > 0 ? (
          <PaginationSection FavoriteAppsData={favoriteAppsList} />
        ) : (
          <EmptyComponent />
        )}
      </FavoritesAppContainer>
    </>
  );
};

export default FavoriteAppSection;
