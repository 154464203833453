import React, { useState, ReactElement, useEffect } from 'react';
import { FavoriteAppData, FavoriteCardComponentType } from '../../../types';
import FavoriteCard from '../FavoriteCard';
import Sortable from '../DragAndDropSorter';
import { Typography } from '@mui/material';

//this mock data has to come from API or some other source
const FavoriteApps: FavoriteAppData[] = [
  { appName: 'App2', appKey: 'accuvio', sectionName: 'Section2', appLink: 'https://diligent.com' },
  { appName: 'App3', appKey: 'storyboards', sectionName: 'Section3', appLink: 'https://diligent.com' },
  { appName: 'App4', appKey: 'equity', sectionName: 'Section4', appLink: 'https://diligent.com' },
  { appName: 'App1', appKey: 'reports', sectionName: 'Section1', appLink: 'https://diligent.com' },
  { appName: 'App5', appKey: 'projects', sectionName: 'Section5', appLink: 'https://diligent.com' },
  { appName: 'App6', appKey: 'projects', sectionName: 'Section6', appLink: 'https://diligent.com' },
  { appName: 'App7', appKey: 'equity-invest', sectionName: 'Section7', appLink: 'https://diligent.com' },
  { appName: 'App8', appKey: 'accuvio', sectionName: 'Section8', appLink: 'https://diligent.com' },
  { appName: 'App9', appKey: 'results', sectionName: 'Section9', appLink: 'https://diligent.com' },
  { appName: 'App10', appKey: 'storyboards', sectionName: 'Section10', appLink: 'https://diligent.com' },
];

function reorder(sortableApps: FavoriteAppData[], source: number, destination: number) {
  const newApps = [...sortableApps];
  const [removed] = newApps.splice(source, 1);
  newApps.splice(destination, 0, removed);
  return newApps;
}
const FavoriteSortableApps = (): ReactElement => {
  const [sortableApps, setsortableApps] = useState<FavoriteAppData[]>(FavoriteApps);

  useEffect(() => {
    // Update the state when FavoriteApps prop changes
    setsortableApps(FavoriteApps);
  }, [FavoriteApps]); // Dependency array ensures this runs when FavoriteApps changes

  function sortChangeHandler(source: number, destination: number | null): void {
    if (destination !== null) {
      const newApps = reorder(sortableApps, source, destination);
      setsortableApps(newApps);
    }
  }

  return (
    <>
      <Typography variant="h3">Favorite</Typography>
      <Sortable onChange={sortChangeHandler}> {renderDraggableItems()}</Sortable>;
    </>
  );

  function renderDraggableItems() {
    return sortableApps.map((app, index) => {
      return (
        <Sortable.sortableElement key={index} sortID={index}>
          <FavoriteCard
            key={app.appKey}
            favoriteAppData={app}
            onFavoriteButtonClick={() => console.log('Favorite clicked')}
            componentType={FavoriteCardComponentType.Draggable}
          />
        </Sortable.sortableElement>
      );
    });
  }
};

export default FavoriteSortableApps;
